import { nanoid } from "nanoid/non-secure";
import {
  AccountInfoEntity,
  ChangePasswordRequest,
  CompanyInformationEntity,
  CreateCompanyRequest,
  ResetPasswordRequest,
  ResultResponse,
} from "@/domain";
import {
  AccountCheckPhoneController_ResultBoolResponse,
  AccountPasswordResetController_EmailRequest,
  CompanyInformationEntity as KuberCompanyInformationEntity,
  PrivateAccountChangePasswordController_NewUserPasswordWithOldPasswordRequest,
  PrivateAccountInformationController_AccountInformationResponse,
  PrivateAccountNewCompanyController_CompanyNewRequest,
} from "../codegen";

export function fromResetPasswordRequest(request: ResetPasswordRequest): AccountPasswordResetController_EmailRequest {
  return request;
}

export function fromChangePasswordRequest(
  request: ChangePasswordRequest,
): PrivateAccountChangePasswordController_NewUserPasswordWithOldPasswordRequest {
  return {
    old_password: request.oldPassword,
    new_password: request.newPassword,
  };
}

export function toResultResponse(response: AccountCheckPhoneController_ResultBoolResponse): ResultResponse {
  return {
    result: response.result_bool_entity ? response.result_bool_entity.result : false,
    message: response.result_bool_entity ? response.result_bool_entity.message ?? undefined : undefined,
  };
}

export function toAccountInfoEntity(
  response: PrivateAccountInformationController_AccountInformationResponse,
): AccountInfoEntity {
  return {
    phone: response.actual_phone,
    email: response.email,
    emailVerified: response.email_verified,
  };
}

export function toCompanyInformationEntity(entity: KuberCompanyInformationEntity): CompanyInformationEntity {
  return {
    id: nanoid(),
    fullName: entity.name_full,
    shortName: entity.name_short ?? undefined,
    inn: entity.inn,
    kpp: entity.kpp ?? undefined,
    type: entity.type,
    managementName: entity.management_name ?? undefined,
    address: entity.address,
    status: entity.status,
  };
}

export function fromCreateCompanyRequest(
  request: CreateCompanyRequest,
): PrivateAccountNewCompanyController_CompanyNewRequest {
  return {
    company: {
      inn: request.inn,
      kpp: request.kpp,
      selfworker: request.selfWorker,
    },
  };
}
