import {
  fetchAddressSuggestions,
  fetchBankSuggestions,
  fetchFioSuggestions,
  fetchFmsUnitsSuggestions,
  fetchPartySuggestions,
} from "@/api/dadata-api";
import { fetchAddressGeoLocationSuggestions } from "@/api/dadata-api/dadata-api";

export const getAddressSuggestions = (query: string) =>
  fetchAddressSuggestions({ query }).then((s) => s.map((i) => ({ label: i.value, value: i.value })));

export const getAddressGeoLocationSuggestions = (query: string) =>
  fetchAddressGeoLocationSuggestions({ query }).then((s) =>
    s.map((i) => ({
      label: i.value,
      value: i.value,
      lat: i.data.geo_lat,
      lon: i.data.geo_lon,
    })),
  );

export const getFmsUnitSuggestions = (query: string) =>
  fetchFmsUnitsSuggestions(query).then((s) => s.map((i) => ({ label: i.value, value: i.value })));

export const getBankSuggestions = (query: string) =>
  fetchBankSuggestions(query).then((s) =>
    s.map((i) => ({
      label: i.value,
      value: i.data.bic,
      corrAccount: i.data.correspondent_account,
      bic: i.data.bic,
      bill: "",
      name: i.value,
    })),
  );

export const getSurnameSuggestions = (query: string) =>
  fetchFioSuggestions({ query, part: "SURNAME" }).then((data) => data.map((i) => ({ value: i.value, label: i.value })));

export const getNameSuggestions = (query: string) =>
  fetchFioSuggestions({ query, part: "NAME" }).then((data) => data.map((i) => ({ value: i.value, label: i.value })));

export const getPatronymicSuggestions = (query: string) =>
  fetchFioSuggestions({ query, part: "PATRONYMIC" }).then((data) =>
    data.map((i) => ({ value: i.value, label: i.value })),
  );

export const getPartySuggestions = (query: string) =>
  fetchPartySuggestions({
    query,
  }).then((s) => s.map((i) => ({ label: i.value, value: i.data.inn })));
