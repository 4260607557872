import axios from "axios";

import {
  DaDataAddressGeoLocationSuggestion,
  DaDataAddressGeoLocationSuggestionsResponse,
  DaDataAddressSuggestion,
  DaDataAddressSuggestionRequest,
  DaDataAddressSuggestionsResponse,
  DaDataBanksSuggestionsResponse,
  DaDataBankSuggestion,
  DaDataFioSuggestion,
  DaDataFioSuggestionsRequest,
  DaDataFioSuggestionsResponse,
  DaDataFmsUnitSuggestion,
  DaDataFmsUnitSuggestionsResponse,
  DaDataPartySuggestion,
  DaDataPartySuggestionsRequest,
  DaDataPartySuggestionsResponse,
} from "./types";

if (!process.env.NEXT_PUBLIC_DADATA_TOKEN) {
  console.warn("DaData: не задана переменная окружения NEXT_PUBLIC_DADATA_TOKEN, работа с ДаДата невозможна");
}

const dadataApi = axios.create({
  headers: {
    Authorization: `Token ${process.env.NEXT_PUBLIC_DADATA_TOKEN}`,
  },
});

export function fetchAddressSuggestions(request: DaDataAddressSuggestionRequest): Promise<DaDataAddressSuggestion[]> {
  if (!process.env.NEXT_PUBLIC_DADATA_ADDRESS_SUGGEST_URL) {
    console.warn(
      "DaData: не задана переменная окружения NEXT_PUBLIC_DADATA_ADDRESS_SUGGEST_URL, получение подсказок по адресам невозможно",
    );
    return Promise.resolve([]);
  }

  return dadataApi
    .post<DaDataAddressSuggestionsResponse>(
      // @ts-ignore
      process.env.NEXT_PUBLIC_DADATA_ADDRESS_SUGGEST_URL!,
      {
        query: request.query,
      },
    )
    .then((response) => response.data.suggestions);
}

export function fetchAddressGeoLocationSuggestions(
  request: DaDataAddressSuggestionRequest,
): Promise<DaDataAddressGeoLocationSuggestion[]> {
  if (!process.env.NEXT_PUBLIC_DADATA_ADDRESS_SUGGEST_URL) {
    console.warn(
      "DaData: не задана переменная окружения NEXT_PUBLIC_DADATA_ADDRESS_SUGGEST_URL, получение подсказок по адресам невозможно",
    );
    return Promise.resolve([]);
  }

  return dadataApi
    .post<DaDataAddressGeoLocationSuggestionsResponse>(
      // @ts-ignore
      process.env.NEXT_PUBLIC_DADATA_ADDRESS_SUGGEST_URL!,
      {
        query: request.query,
      },
    )
    .then((response) => response.data.suggestions);
}

export function fetchBankSuggestions(query: string): Promise<DaDataBankSuggestion[]> {
  if (!process.env.NEXT_PUBLIC_DADATA_BANK_SUGGEST_URL) {
    console.warn(
      "DaData: не задана переменная окружения NEXT_PUBLIC_DADATA_BANK_SUGGEST_URL, получение подсказок по банкам невозможно",
    );
    return Promise.resolve([]);
  }

  return dadataApi
    .post<DaDataBanksSuggestionsResponse>(
      // @ts-ignore
      process.env.NEXT_PUBLIC_DADATA_BANK_SUGGEST_URL!,
      {
        query,
      },
    )
    .then((response) => response.data.suggestions);
}

/**
 *
 * @param request
 * @returns Массив элементов с подходящими под критерии поиска фирмами / физ.лицами
 */
export function fetchPartySuggestions(request: DaDataPartySuggestionsRequest): Promise<DaDataPartySuggestion[]> {
  if (!process.env.NEXT_PUBLIC_DADATA_PARTY_SUGGEST_URL) {
    console.warn(
      "DaData: не задана переменная окружения NEXT_PUBLIC_DADATA_PARTY_SUGGEST_URL, получение подсказок по организациям невозможно",
    );
    return Promise.resolve([]);
  }

  return dadataApi
    .post<DaDataPartySuggestionsResponse>(process.env.NEXT_PUBLIC_DADATA_PARTY_SUGGEST_URL!, {
      ...request,
    })
    .then((response) => response.data.suggestions);
}

/**
 *
 * @param query
 * @returns Массив элементов с подходящими под критерии поиска ФМС
 */
export function fetchFmsUnitsSuggestions(query: string): Promise<DaDataFmsUnitSuggestion[]> {
  if (!process.env.NEXT_PUBLIC_DADATA_FMS_UNIT_SUGGEST_URL) {
    console.warn(
      "DaData: не задана переменная окружения NEXT_PUBLIC_DADATA_FMS_UNIT_SUGGEST_URL, получение подсказок по ФМС невозможно",
    );
    return Promise.resolve([]);
  }

  return dadataApi
    .post<DaDataFmsUnitSuggestionsResponse>(
      // @ts-ignore
      process.env.NEXT_PUBLIC_DADATA_FMS_UNIT_SUGGEST_URL!,
      {
        query,
      },
    )
    .then((response) => response.data.suggestions);
}

/**
 *
 * @param query
 * @returns Массив элементов ФИО, подходящих под критерий поиска
 */
export function fetchFioSuggestions(request: DaDataFioSuggestionsRequest): Promise<DaDataFioSuggestion[]> {
  if (!process.env.NEXT_PUBLIC_DADATA_FIO_SUGGEST_URL) {
    console.warn(
      "DaData: не задана переменная окружения NEXT_PUBLIC_DADATA_FIO_SUGGEST_URL, получение подсказок по ФМС невозможно",
    );
    return Promise.resolve([]);
  }

  return dadataApi
    .post<DaDataFioSuggestionsResponse>(
      // @ts-ignore
      process.env.NEXT_PUBLIC_DADATA_FIO_SUGGEST_URL!,
      {
        query: request.query,
        parts: [request.part],
      },
    )
    .then((response) => response.data.suggestions);
}
