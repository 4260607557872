import {
  AttachmentEntity,
  BalanceOperationEntity,
  BillingCreditEntity,
  CustomerBillingInfo,
  DepositResponse,
  FileEntity,
  FineEntity,
  GetBalanceOperationsHistoryResponse,
  GetBillingCreditsResponse,
  GetFinesResponse,
  GetInvoicesResponse,
  GetWithdrawalsResponse,
  InvoiceEntity,
  MoverBillingInfo,
  ResultResponse,
  WithdrawalEntity,
} from "@/domain";
import {
  AddCustomerInvoicingBillingController_AddInvoicingBillingResponse,
  AddMoverInvoicingBillingController_AddInvoicingBillingResponse,
  BillingApplicationWithdrawEntity,
  BillingCreditEntity as KuberBillingCreditEntity,
  BillingFinesEntity,
  BillingHistoryOperationEntity,
  BillingIssuedInvoicesEntity,
  GetCustomerBillingApplicationWithdrawController_BillingApplicationWithdrawResponse,
  GetCustomerBillingCreditController_BillingCreditResponse,
  GetCustomerBillingFinesController_BillingFinesResponse,
  GetCustomerBillingHistoryOperationController_BillingHistoryOperationResponse,
  GetCustomerBillingInfoController_CustomerBillingInfoResponse,
  GetCustomerBillingInvoicesController_BillingInvoicesResponse,
  GetMoverBillingApplicationWithdrawController_BillingApplicationWithdrawResponse,
  GetMoverBillingFinesController_BillingFinesResponse,
  GetMoverBillingHistoryOperationController_BillingHistoryOperationResponse,
  GetMoverBillingInfoController_MoverBillingInfoResponse,
  GetMoverBillingInvoicesController_BillingInvoicesResponse,
  OutputFileEntity,
  ResultBoolEntity,
} from "../codegen";

export function toCustomerBillingInfo(
  response: GetCustomerBillingInfoController_CustomerBillingInfoResponse,
): CustomerBillingInfo {
  // TODO: codegen
  return {
    personalBalance: response.customer_billing_info_entity?.personal_balance ?? 0,
    creditBalance: response.customer_billing_info_entity?.credit_balance ?? 0,
    isDebtor: response.customer_billing_info_entity?.debtor ?? false,
    creditLimit: response.customer_billing_info_entity?.credit_limit ?? 0,
    sumToPayCreditLimit: response.customer_billing_info_entity?.sum_to_pay_credit_limit ?? 0,
    sumFine: response.customer_billing_info_entity?.sum_fine ?? 0,
    sumOverdueCreditLimit: response.customer_billing_info_entity?.sum_overdue_credit_limit ?? 0,
    reservedSum: response.customer_billing_info_entity?.reserved_sum ?? 0,
  };
}

export function toMoverBillingInfo(response: GetMoverBillingInfoController_MoverBillingInfoResponse): MoverBillingInfo {
  return {
    personalBalance: response.mover_billing_info_entity?.personal_balance ?? 0,
    isDebtor: response.mover_billing_info_entity?.debtor ?? false,
    autoBalanceWithdraw: response.mover_billing_info_entity?.auto_balance_withdraw ?? false,
    sumFine: response.mover_billing_info_entity?.sum_fine ?? 0,
  };
}

function toFileEntity(entity: OutputFileEntity): FileEntity {
  return {
    id: entity.attachment_name,
    originalName: entity.original_name,
    extension: entity.extension.value!,
  };
}

export function toInvoiceEntity(entity: BillingIssuedInvoicesEntity): InvoiceEntity {
  // TODO: codegen
  return {
    id: entity.number_invoice!,
    date: entity.date_invoice!,
    sum: entity.sum ?? 0,
    status: {
      id: entity.dir_invoice_status_enum?.value!,
    },
    createdAt: entity.created_at!,
    updatedAt: entity.updated_at!,
    file: entity.file_invoice ? toFileEntity(entity.file_invoice) : undefined,
    note: entity.note ?? undefined,
    requisites: entity.requisites ?? undefined,
  };
}

export function toGetInvoicesResponse(
  response:
    | GetCustomerBillingInvoicesController_BillingInvoicesResponse
    | GetMoverBillingInvoicesController_BillingInvoicesResponse,
): GetInvoicesResponse {
  return {
    // TODO: codegen
    items: response.billing_issued_invoices ? response.billing_issued_invoices.map(toInvoiceEntity) : [],
    // TODO: codegen
    pagination: response.pagination ?? { total: 0, offset: 0, limit: 0 },
  };
}

export function toFineEntity(entity: BillingFinesEntity): FineEntity {
  // TODO: codegen
  return {
    id: entity.number_fines!,
    createdAt: entity.created_at!,
    sum: entity.sum!,
    status: entity.status_fine?.value!,
    // docs: entity.
    //   ? entity.docs_fines?.map((f) => ({
    //       // TODO: codegen
    //       id: f.attachment_name!,
    //       // TODO: codegen
    //       originalName: f.original_name!,
    //       // TODO: codegen
    //       extension: f.extension?.value!,
    //       // TODO: codegen
    //       mimeType: f.mime_type?.value!,
    //       // TODO: codegen
    //       typeId: f.document_type?.value!,
    //       // TODO: codegen
    //       typeName: "",
    //       comment: "",
    //     }))
    //   : [],
    note: entity.note ?? undefined,
    orderNumbers: entity.order_numbers ?? [],
  };
}

export function toGetFinesResponse(
  response:
    | GetCustomerBillingFinesController_BillingFinesResponse
    | GetMoverBillingFinesController_BillingFinesResponse,
): GetFinesResponse {
  return {
    items: response.billing_fines ? response.billing_fines.map(toFineEntity) : [],
    // TODO: codegen
    pagination: response.pagination!,
  };
}

export function toWithdrawalEntity(entity: BillingApplicationWithdrawEntity): WithdrawalEntity {
  // TODO: codegen
  return {
    id: entity.number_application!,
    sum: entity.sum!,
    requisites: entity.requisites!,
    createdAt: entity.created_at!,
    status: { id: entity.dir_withdrawal_request_status_enum?.value! },
    note: entity.note ?? undefined,
  };
}

export function toGetWithdrawalsResponse(
  response:
    | GetCustomerBillingApplicationWithdrawController_BillingApplicationWithdrawResponse
    | GetMoverBillingApplicationWithdrawController_BillingApplicationWithdrawResponse,
): GetWithdrawalsResponse {
  return {
    // TODO: pagination
    items: response.billing_application_withdraw ? response.billing_application_withdraw.map(toWithdrawalEntity) : [],
    pagination: response.pagination!,
  };
}

export function toBalanceOperationEntity(entity: BillingHistoryOperationEntity): BalanceOperationEntity {
  return {
    transactionNumber: entity.number_transaction!,
    createdAt: entity.created_at!,
    sum: entity.sum ?? 0,
    transactionType: {
      id: entity.dir_operating_type_enum?.value!,
    },
    billType: {
      id: entity.dir_bill_type_enum?.value!,
    },
    note: entity.note ?? undefined,
    billBalance: entity.bill_balance ?? 0,
  };
}

export function toGetBalanceOperationsHistoryResponse(
  response:
    | GetCustomerBillingHistoryOperationController_BillingHistoryOperationResponse
    | GetMoverBillingHistoryOperationController_BillingHistoryOperationResponse,
): GetBalanceOperationsHistoryResponse {
  return {
    items: response.billing_history_operation_entity
      ? response.billing_history_operation_entity.map(toBalanceOperationEntity)
      : [],
    pagination: response.pagination!,
  };
}

export function toBillingCreditEntity(entity: KuberBillingCreditEntity): BillingCreditEntity {
  // TODO: codegen
  return {
    id: entity.number_credit!,
    sum: entity.sum ?? 0,
    creditStartDate: entity.credit_start_date!,
    creditEndDate: entity.credit_end_date!,
    orderNumbers: entity.order_numbers ?? [],
    status: {
      id: entity.status_credit?.value!,
    },
    note: entity.note ?? undefined,
    docPackageId: entity.document_package_id ?? "",
  };
}

export function toGetBalanceCreditsResponse(
  response: GetCustomerBillingCreditController_BillingCreditResponse,
): GetBillingCreditsResponse {
  return {
    items: response.billing_credits ? response.billing_credits.map((e) => toBillingCreditEntity(e)) : [],
    pagination: response.pagination!,
  };
}

export function toAttachmentEntity(entity: OutputFileEntity): AttachmentEntity {
  return {
    id: entity.attachment_name,
    originalName: entity.original_name,
    mimeType: entity.mime_type.value!,
    extension: entity.extension.value!,
    link: entity.link!,
  };
}

export function toDepositResponse(
  response:
    | AddCustomerInvoicingBillingController_AddInvoicingBillingResponse
    | AddMoverInvoicingBillingController_AddInvoicingBillingResponse,
): DepositResponse {
  return {
    // TODO: codegen
    invoiceId: response.number_invoice!,
    invoiceFile: toAttachmentEntity(response.file_invoice!),
  };
}

export function toResultResponse(response: ResultBoolEntity): ResultResponse {
  return {
    result: response.result,
    message: response.message ?? undefined,
  };
}
