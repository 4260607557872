import { clientDefault } from "../client";
import { GET_NEWS_API, GET_ONE_NEWS_API, POST_MAIN_FORM } from "../endpoints";
import {
  MainFormApplicationController_ApplicationMainRequest,
  MainFormApplicationController_ResultBoolResponse,
  NewsController_NewsResponse,
  NewsItemPageController_NewsItemResponse,
} from "../codegen";
import { GetNewsRequest, GetOneNewsRequest } from "@/domain";

/**
 * заявка на подключение
 * docs: http://84.201.143.108:8990/front/#tag/Main_page/operation/v1.0Main_pageMain_formaSend
 */
export function sendRequestForm(
  request: MainFormApplicationController_ApplicationMainRequest,
): Promise<MainFormApplicationController_ResultBoolResponse> {
  return clientDefault
    .post<MainFormApplicationController_ResultBoolResponse>(POST_MAIN_FORM, request)
    .then((response) => response.data);
}

export function getNews(request: GetNewsRequest): Promise<NewsController_NewsResponse> {
  return clientDefault.get(`/${GET_NEWS_API}?limit=${request.limit}&offset=${request.offset}`);
}

export function getOneNews(request: GetOneNewsRequest): Promise<NewsItemPageController_NewsItemResponse> {
  return clientDefault.get(`/${GET_ONE_NEWS_API}?newsId=${request.newsId}`).then((response) => response.data);
}
