import { misc } from "@/api/kuber-api";
import {
  GetNewsRequest,
  GetNewsResponse,
  GetOneNewsRequest,
  RequestConsultationRequest,
  ResultResponse,
} from "@/domain";
import { transformError } from "@/shared";
import { NewsEntity } from "@/api/kuber-api/codegen";

export function requestConsultation(request: RequestConsultationRequest): Promise<ResultResponse> {
  return misc.api
    .sendRequestForm(misc.mappers.fromRequestConsultationRequest(request))
    .then(misc.mappers.toResultResponse)
    .catch((error) => {
      throw transformError(error);
    });
}

export function getNews(request: GetNewsRequest): Promise<GetNewsResponse> {
  return misc.api.getNews(request).then(misc.mappers.toGetNewsResponse);
}

export function getOneNews(request: GetOneNewsRequest): Promise<NewsEntity> {
  return misc.api
    .getOneNews(request)
    .then((response) => response.entity)
    .catch((error) => {
      throw transformError(error);
    });
}
