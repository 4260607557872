import {
  DocumentFileEntity,
  LicenseEntity,
  PaginatedRequest,
  PaginatedResponse,
  PassportEntity,
  PersonEntity,
} from "./common";
import { OrderStatusType, TariffTypeValue } from "@/data/constants";
import {
  type AgOrderEntity,
  type AgOrderInputDocumentEntity,
  DateTimePeriod,
  TariffCalculationEntity,
} from "@/api/kuber-api/codegen";
import { MarkerName, PackageName } from "@/admin/data/constants";

export const FIRST_MILE_DELIVERY_TYPE = "first_mile" as const;
export const LAST_MILE_DELIVERY_TYPE = "last_mile" as const;
export const EMPTY_CONTAINER_DELIVERY_TYPE = "empty_container" as const;

export type DeliveryType =
  | typeof FIRST_MILE_DELIVERY_TYPE
  | typeof LAST_MILE_DELIVERY_TYPE
  | typeof EMPTY_CONTAINER_DELIVERY_TYPE;
export type LocationType = "msk" | "spb" | "nsk";
export type ContainerType = string;
export type ContainerClass = string;
export type HowToCarry = string;

export type ActualOrderEntity = {
  /**
   * Вид доставки
   */
  deliveryType: DeliveryType;
  /**
   * Место получения (адрес терминала)
   */
  pickupAdddress: string;
  /**
   * Место доставки (обрезаем) - город и улица
   */
  warehouseAddress?: string;
  /**
   * Место сдачи (адрес терминала)
   */
  deliveryAddress: string;
  /**
   * тариф без НДС
   */
  moverTariff: number;
};

export type CalculateTariffRequestCargo = {
  containerHeight?: string;
  containerNumber?: string;
  cargoName?: string;
  cargoCost?: number;
  cargoWeight?: number;
};

export type CalculateOrderTariffPublicRequest = {
  deliveryType: DeliveryType;

  pickupCity: LocationType;
  pickupTerminal: string;
  deliveryCity: LocationType;
  deliveryTerminal: string;
  howToCarry?: string;
  containerType: string;
  containerClass?: string;
  warehouseAddress?: string;

  cargos: CalculateTariffRequestCargo[];
};

export type GetOrderByIdResponse = {
  order: OrderEntity;
  documents: OrderDocumentEntity[];
  noFormatTariffs: TariffCalculationEntity[];
  tariffs: OrderTariffEntity[];
  mover?: OrderMoverEntity;
  statusChain: OrderStatusEntry[];
  transportConfirmation?: TransportConfirmationEntity;
  documentPackages: OrderDocumentPackage[];
  noFormatOrder?: AgOrderEntity;
};

export type OrderDocumentPackage = {
  documentPackageId: string;
  createdAt: string;
  type: PackageName;
};
export type OrderIdType = string;
export type OrderDocumentEntity = Omit<DocumentFileEntity, "typeName">;
export type OrderTerminal = string;
export type OrderEntity = {
  id: OrderIdType;
  createdAt: string;
  deliveryType: DeliveryType;
  cargos: OrderCargo[];
  cargoProperties: OrderCargoProperties;
  pickupCity: LocationType;
  pickupTerminal: OrderTerminal;
  warehouseAddress?: string;
  warehouseDateTimePeriod?: DateTimePeriod;
  deliveryCity: LocationType;
  deliveryTerminal: OrderTerminal;
  contacts: WarehouseContact[];
  paymentType: PaymentType;
  options: OrderOption[];
  additionalInfo?: string;
  containerType: ContainerType;
  containerClass?: ContainerClass;
  howToCarry?: HowToCarry;
  warehouseCompany?: AdditionalCompanyInfo;
  shipperCompany: AdditionalCompanyInfo;
  consigneeCompany: AdditionalCompanyInfo;
  clientCompany?: AdditionalCompanyInfo;
  currentCustomerStatus: OrderStatusType;
};

export type OrderMoverEntity = {
  truck?: VehicleEntity;
  semiTrailer?: VehicleEntity;
  driver?: PersonEntity;
  driverPassport?: PassportEntity;
  driverLicense?: LicenseEntity;
};
type VehicleEntity = {
  brand: string;
  model: string;
  govNumber: string;
};

export type WarehouseContact = {
  name: string;
  phone: string;
};
type OrderOption = string;
type PaymentType = string;
type WarehouseDeliveryTimePeriod = string;
export type AdditionalCompanyInfo = {
  inn: string;
  opf: string;
  name: string;
};

export type OrderCargo = {
  containerHeight?: string;
  containerNumber?: string;
  cargoWeight?: number;
  cargoName?: string;
  cargoCost?: number;
  zpu?: string;
};

type OrderCargoProperties = {
  dangerClass?: number;
  temperatureFrom?: number;
  temperatureTo?: number;
};

export type OrderTariffType = TariffTypeValue;
export type OrderTariffEntity = {
  customerFullTariff: number;
  customerFullTariffNds: number;
  oldFullTariff: number;
  baseTariff: number;
  baseTariffNds: number;
  options: OrderTariffOptionEntity[];
  additionals: OrderTariffOptionEntity[];
  discounts: OrderTariffOptionEntity[];
  type: OrderTariffType;
  isActive: boolean;
  isSelected: boolean;
};

export type TariffAdditionalInfo = {
  description: string;
  calculateFormula: string;
};
type OrderTariffOptionEntity = {
  name: string;
  sum: number;
  nds?: number;
};
export type OrderStatusEntry = {
  status: { id: string; state?: StatusStepState };
  comment?: string;
  cancellationReason?: string;
  createdAt?: string;
};
export type TransportConfirmationEntity = {
  text: string;
  textConfirmed?: string;
  isButtonActive: boolean;
};

export type CancelOrderRequest = {
  orderId: OrderIdType;
  comment: string;
};

export type CalculateTariffRequest = {
  deliveryType: DeliveryType;

  pickupCity: LocationType;
  pickupTerminal: string;
  deliveryCity: LocationType;
  deliveryTerminal: string;
  howToCarry?: string;
  containerType: string;
  containerClass?: string;
  cargoProperties?: OrderCargoProperties;
  cargos: CalculateTariffRequestCargo[];
  paymentType: PaymentType;
  options: OrderOption[];
  warehouseAddress?: string;
  warehouseDeliveryDate?: string;

  promo?: string;
};

export type CreateOrderRequest = {
  deliveryType: DeliveryType;

  pickupCity: LocationType;
  pickupTerminal: string;
  deliveryCity: LocationType;
  deliveryTerminal: string;
  howToCarry?: string;
  containerType: string;
  containerClass?: string;
  cargoProperties?: OrderCargoProperties;
  cargos: OrderCargo[];
  paymentType: PaymentType;
  shipperInn: string;
  consigneeInn: string;
  clientInn?: string;
  additionalInfo?: string;
  options: OrderOption[];
  tariffs: OrderTariffEntity[];

  // empty container
  // deliveryDateFrom?: string;
  // deliveryDateTo?: string;

  // first / last mile
  warehouseAddress?: string;
  warehouseCompanyInn?: string;
  warehouseDeliveryDate?: string;
  warehouseDeliveryTimePeriod?: WarehouseDeliveryTimePeriod;
  warehouseContacts: WarehouseContact[];
  attachments?: AgOrderInputDocumentEntity[];

  promo?: string;
};

export type UpdateOrderTariffsRequest = {
  orderId: OrderIdType;
  tariffs: OrderTariffEntity[];
};

export type OrderListEntity = {
  id: string;
  createdAt: string;
  pickupCity: LocationType;
  deliveryCity: LocationType;
  deliveryType: DeliveryType;
  containerNumber?: string;
  containerNumber2?: string;
  containerHeight?: string;
  containerType?: string;
  deliveryTerminal?: string;
  pickupTerminal?: string;
  warehouseAddress?: string;
  deliveryDateTimePeriod?: DateTimePeriod;
  statusId: string;
  tariffs: { tariff: number; type: OrderTariffType }[];
};

export type SearchOrdersFilter = {
  status?: string;
  pickupCity?: LocationType;
  pickupTerminal?: string;
  deliveryCity?: LocationType;
  deliveryTerminal?: string;
  warehouseAddress?: string;
  deliveryType?: DeliveryType;
  containerType?: string;
  cargoWeightFrom?: number;
  cargoWeightTo?: number;
  paymentType?: string;
  deliveryDateFrom?: string;
  deliveryDateTo?: string;
  options?: string[];
  deliveryTime?: string;
};
export type SearchOrdersRequest = PaginatedRequest & {
  filter: SearchOrdersFilter;
};
export type SearchOrdersResponse = PaginatedResponse<OrderListEntity>;

export type StatusStepState = "inactive" | "passed" | "active" | "error";

export type RoutePointTuple = [number, number];
export type MarkerTypePoint = {
  name: string;
  address: string;
  description: string;
  mapPoint: RoutePointTuple;
  markerType?: MarkerName;
};

export type MapResponse = {
  markers: MarkerTypePoint[];
  routeMap: RoutePointTuple[][];
};
