import { useCallback, useEffect } from "react";
import { atom, useAtom, useSetAtom } from "jotai";
import { atomWithStorage, useUpdateAtom } from "jotai/utils";
import jwtDecode from "jwt-decode";

import * as tokenStorage from "../tokens";
import { AuthTokensResponse, ProfileType } from "@/domain";
import { JWTRoleValue } from "@/data/constants";
import { useRouter } from "next/router";
import { AxiosError } from "axios";
import { auth } from "@/services";

interface User {
  user_uuid: string;
  user_roles: JWTRoleValue[];
  user_information: any[];
  user_email_verified: boolean;
  user_actual_email: string;
}

class CurrentUser {
  readonly uuid: string;
  readonly email: string;
  readonly isEmailVerified: boolean;
  readonly roles: JWTRoleValue[];
  readonly isRegistrationCompleted: boolean;
  readonly isMover: boolean;
  readonly isCustomer: boolean;
  readonly isAdmin: boolean;
  readonly isManager: boolean;
  readonly isSupport: boolean;
  readonly isSuperUser: boolean;
  readonly isAtiAuthorizationRequired: boolean;
  readonly profileType: ProfileType;
  readonly isAdminProfile: boolean;
  readonly isUserProfile: boolean;
  readonly isDevelopment: boolean;

  constructor(json: User) {
    this.uuid = json.user_uuid;
    this.roles = json.user_roles;
    this.email = json.user_actual_email;
    this.isEmailVerified = json.user_email_verified;
    this.isRegistrationCompleted = this.roles.indexOf("ROLE_COMPANY_COMPLETED") !== -1;
    this.isMover = this.roles.indexOf("ROLE_MOVER") !== -1;
    this.isCustomer = this.roles.indexOf("ROLE_CUSTOMER") !== -1;
    this.profileType = this.isMover ? "mover" : "customer";
    this.isAdmin = this.roles.indexOf("ROLE_ADMIN") !== -1;
    this.isManager = this.roles.indexOf("ROLE_MANAGER") !== -1;
    this.isSupport = this.roles.indexOf("ROLE_SUPPORT") !== -1;
    this.isSuperUser = this.roles.indexOf("ROLE_SUPERUSER") !== -1;
    this.isAtiAuthorizationRequired = this.isMover && this.roles.indexOf("ROLE_ATI_AUTHORIZED") === -1;
    this.isAdminProfile = this.isAdmin || this.isSuperUser || this.isSupport || this.isManager;
    this.isUserProfile = this.isMover || this.isCustomer;
    this.isDevelopment = this.roles.indexOf("ROLE_DEVELOPMENT") !== -1;
  }
}

const accessTokenAtom = atomWithStorage<string | null>(
  tokenStorage.ACCESS_TOKEN_STORAGE_KEY,
  tokenStorage.getAccessToken(),
);

const refreshTokenAtom = atomWithStorage<string | null>(
  tokenStorage.REFRESH_TOKEN_STORAGE_KEY,
  tokenStorage.getRefreshToken(),
);

const userAtom = atom((get) => {
  const token = get(accessTokenAtom);
  return mapTokenToUser(token);
});

function mapTokenToUser(token: string | null) {
  if (!token) return null;
  const decoded = new CurrentUser(jwtDecode<User>(token));
  return decoded;
}

let called = false;

export function useAccessToken() {
  return useSetAtom(accessTokenAtom);
}

export function useRefreshToken() {
  return useSetAtom(refreshTokenAtom);
}

export default function useUser() {
  const [user] = useAtom(userAtom);
  const updateAccessToken = useUpdateAtom(accessTokenAtom);
  const router = useRouter();

  // subscribe to localstorage change in other tab
  // subscribe to post message from axios interceptor
  useEffect(() => {
    if (called) return;

    function handlePostMessage(message: MessageEvent) {
      if (!message.data) return;

      if (message.data.type === "KUBER.TOKENS") {
        updateTokens(message.data.tokens);
      }
    }

    function handleTokenChange(event: StorageEvent) {
      if (event.key === null || event.key === tokenStorage.ACCESS_TOKEN_STORAGE_KEY) {
        updateAccessToken(tokenStorage.getAccessToken());
      }
    }

    window.addEventListener("storage", handleTokenChange);
    window.addEventListener("message", handlePostMessage);
    called = true;

    return () => {
      window.removeEventListener("storage", handleTokenChange);
      window.removeEventListener("message", handlePostMessage);
    };
  }, []);

  const updateTokens = async (tokens: AuthTokensResponse) => {
    updateAccessToken(tokens.accessToken);
    tokenStorage.setRefreshToken(tokens.refreshToken);

    return Promise.resolve(mapTokenToUser(tokens.accessToken));
  };

  const logoutUser = useCallback(async () => {
    await router.push("/");
    updateAccessToken(null);
    tokenStorage.setRefreshToken(null);
  }, []);

  const logoutAdmin = useCallback(async () => {
    await router.push("/auth/admin");
    updateAccessToken(null);
    tokenStorage.setRefreshToken(null);
  }, []);

  const logoutUserWithoutRedirect = () => {
    updateAccessToken(null);
    tokenStorage.setRefreshToken(null);
  };

  const updateUser = async () => {
    const refreshToken = tokenStorage.getRefreshToken();
    if (!refreshToken) return;

    auth
      .refreshAccessToken(refreshToken)
      .then((data) => {
        if (typeof data.accessToken === "undefined" || typeof data.refreshToken === "undefined") return;

        return updateTokens(data);
      })
      .catch((err: Error | AxiosError) => {
        function isAxiosError(err: any): err is AxiosError {
          return typeof err.isAxiosError === "boolean";
        }

        if (isAxiosError(err) && err.response?.status === 400) {
          logoutUser();
        }
      });
  };

  return {
    authenticated: !!user,
    user,
    updateTokens,
    logout: logoutUser,
    logoutWithoutRedirect: logoutUserWithoutRedirect,
    updateUser,
    logoutAdmin,
  };
}
